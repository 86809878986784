<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              单据编号：
              <el-input
                v-model="parameter.invoiceBillNo"
                class="ipt_width expense__width"
                placeholder="请输入单据单编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              单据类型：
              <el-select
                v-model="parameter.billType"
                class="ipt_width expense__width"
                placeholder="请选择单据类型"
                clearable
              >
                <el-option v-for="item in billTypeList" :key="item.id" :label="item.dictName" :value="item.dictVal"/>
              </el-select>
            </div>
            <div class="search_center">
              发票号码：
              <el-input
                v-model="parameter.invoceNo"
                class="ipt_width expense__width"
                placeholder="请输入发票号码"
                clearable
              >
              </el-input>
            </div>

            
            
            <div class="search_center">
              发票类型：
              <el-select
                v-model="parameter.invoiceType"
                clearable
                placeholder="请选择发票类型"
                class="ipt_width"
              >
                <el-option
                  v-for="item in expenseTypes"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div class="search_center">
              发票代码：
              <el-input
                v-model="parameter.invoiceCode"
                class="ipt_width expense__width"
                placeholder="请输入发票代码"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center">
              单据状态：
              <Dictionary
                v-model="parameter.billStatus"
                class="ipt_width"
                code="INVOICE_BILL_STATUS"
                placeholder="请选择单据状态"
              />
            </div>
            

            <el-button
              type="primary"
              icon="el-icon-search"
              @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button
              type="warning"
              icon="el-icon-refresh"
              @click="handleReset"
              >清空</el-button
            >
          </div>
        </div>
        <!-- <div class="fr">
            <el-button type="primary" @click="dialogVisible = true">申请流程</el-button>
            <el-button type="success" style="padding: 0"
              ><a
                style="display: block; height: 40px; width: 100px; line-height: 40px"
                target="_blank"
                href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/合同发票操作文档.pdf"
                >操作文档</a
              ></el-button
            >
            <el-button
              type="primary"
              icon="el-icon-printer"
              :loading="exportingLoading"
              v-if="permission(['CONTRACT_INVOICE_EXPORT'])"
              @click="exportingFn"
              >导出</el-button
            >
          </div> -->
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            ></el-table-column>
            <el-table-column
              prop="billType"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="单据类型"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.billType | dict(billTypeList) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="billStatus"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="单据状态"
              align="center"
            >
              <template slot-scope="scope">
                <!-- {{ scope.row.billStatus | dict(billStatus) }} -->
                {{ scope.row.billStatus }}
              </template>
            </el-table-column>
            <el-table-column
              prop="invoiceBillNo"
              label="单据编号"
              sortable
              align="center"
              min-width="160"
            >
              <template slot-scope="scope">
                <!-- <router-link
                  :to="{
                    name: 'ContractInvoiceDetail',
                    params: { id: scope.row.id },
                  }"
                  >{{ scope.row.invoiceBillNo }}</router-link
                > -->
                <a style="color: blue; text-decoration:underline;" href="javascript:void(0)" @click="handleDetails(scope.row)">
                  {{ scope.row.invoiceBillNo }}
                </a>
              </template>
            </el-table-column>
            <el-table-column
              prop="applicant"
              label="申请人"
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="companyType"
              label="归属法人单位"
              :show-overflow-tooltip="false"
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="customersUnit"
              label="开票单位"
              :show-overflow-tooltip="false"
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="taxpayerNumber"
              label="统一社会信用代码"
              align="center"
              min-width="180"
            ></el-table-column>
            <el-table-column
              prop="invoiceType"
              :show-overflow-tooltip="false"
              min-width="160"
              sortable
              label="发票类型"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.invoiceType | dict(expenseTypes) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="invoiceCode"
              label="发票代码"
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="invoceNo"
              label="发票号码"
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="invoiceDate"
              min-width="110"
              sortable
              label="开票日期"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.invoiceDate | dateFormat }}
              </template>
            </el-table-column>
            <!-- 价税合计 -->
            <el-table-column
              prop="priceTaxTotal"
              :show-overflow-tooltip="false"
              min-width="110"
              sortable
              label="价税合计"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.priceTaxTotal | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="taxRate"
              :show-overflow-tooltip="false"
              min-width="110"
              sortable
              label="税率"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taxRate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="amountExcludingTax"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="不含税金额"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.amountExcludingTax | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="amountExcludingTax"
              :show-overflow-tooltip="false"
              min-width="110"
              sortable
              label="税额"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taxAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="goodsOrTaxableServiceName"
              :show-overflow-tooltip="false"
              min-width="350"
              sortable
              label="货物或应税服务名称"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.goodsOrTaxableServiceName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="invoiceRemarks"
              :show-overflow-tooltip="false"
              min-width="350"
              sortable
              label="发票备注"
              align="center"
            ></el-table-column>
            <!-- <el-table-column label="操作" fixed="right" align="center" min-width="70">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Details_Bgc"
                    @click.stop="handleDetails(scope.row)"
                  >
                    详情
                  </el-button>
                </template>
              </el-table-column> -->
          </el-table>
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            :total="parameter.total"
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input
                size="mini"
                v-model.number="pageSize"
                @blur="handlePageSize"
              ></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    
    <EditDialog
      width="80%"
      @closeFn="editShow = false"
      :isReturn="true"
      v-if="editShow"
      :isShow.sync="editShow"
    >
      <div slot="content" class="content" style="min-height: 600px">
        <!-- <Cost :isDisable="true" :options="costOptions"></Cost> -->
        <!-- <Edit
          :isShow.sync="editShow"
          :getProjectList="projectList"
          :options="options"
        ></Edit> -->
        <component :is="getDetailComponent(options)" :isShow.sync="editShow" :options = "options"></component>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="editShow = false">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
    Edit: () => import("../dlg/Edit.vue"),
    BusinessOutsourceCostEdit: () => import("../../businessOutsourceCost/dlg/Edit.vue"),
    BusinessCostEdit: () => import('../../cost/businessCost/Edit.vue'),
    PublicPaymentEdit: () => import("../../publicPayment/dlg/Edit.vue"),
    PublicReimbursementEdit: () => import("../../pubicSpending/edit/CostEdit.vue"),
    ProjectReimbursementEdit: () => import("../../projectCost/projectCost/Edit.vue"),
    ProjectPaymentEdit: () => import("../../payment/dlg/Edit.vue"),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import("@/components/Dictionary.vue"),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        invoiceBillNo: null,
        billStatus: null,
        billType: null,
        invoiceType: null,
        invoiceCode: null,
        invoiceNo: null,
        order: 'created_date',
        orderRule: 'DESC',
        pageNow: 1,
        pageSize: 50,
        total: 0,
        isUninitiatedProjectInvoice: false,
      },
      expenseStatus: [],
      expenseTypes: [],
      pageSize: 0,
      tableData: [], // 表格数据
      companyType: [],
      offsetStatus: [],
      projectList: [], // 项目数据
      agreementList: [], // 合同数据
      billTypeList: [], //开票类型
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      options: {},
      dialogVisible: false, //流程弹窗
      exportingLoading: false,
    };
  },
  provide() {
    return {
      re: this.closeSubgroup,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString();
      } else {
        return n;
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog;
    this.parameter.pageSize = this.userInfo.pageSizeLog;
    this.getProjectList(); /** 加载 "项目/合同" 数据invoice **/
    this.getTableData();
  },
  watch: {},
  methods: {
    getDetailComponent(options){
      if(options?.expenseId){
        options.id = options.expenseId
      }
      if(options.billType == 'business_outsource_expense'){   // 商务外协费用
        return 'BusinessOutsourceCostEdit'; 
      } else if(options.billType == 'business_expense_reimbursement') { // 商务费用报销
        return 'BusinessCostEdit';
      } else if(options.billType == 'public_payment_application') { // 公共付款申请
        return 'PublicPaymentEdit';
      } else if(options.billType == 'public_expense_reimbursement') { // 公共费用报销
        return 'PublicReimbursementEdit';
      } else if(options.billType == 'project_expense_reimbursement') { // 项目报销
        return 'ProjectReimbursementEdit';
      } else if(options.billType == 'project_payment_application') { // 项目付款申请
        return 'ProjectPaymentEdit';
      }
    },
    /** 加载 "项目/合同" 数据 **/
    getProjectList() {
      this.$api.invoice.queryInvoiceType().then(res=>{
        this.expenseTypes = res.data
      }).catch(err=>{
        console.log(err)
      })
      this.$api.dict
        .listSysDictData("bill_type_purchase", true)
        .then((res) => {
          this.billTypeList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api.dict
        .listSysDictData("INVOICE_TYPE", true)
        .then((res) => {
          // console.log('expenseTypes', res.data)
          // this.expenseTypes = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api.dict
        .listSysDictData("OFFSET_STATUS", true)
        .then((res) => {
          this.offsetStatus = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api.dict
        .listSysDictData("COMPANY_TYPE_FULL", true)
        .then((res) => {
          this.companyType = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then((res) => {
          this.projectList = res.data ? res.data : [];
        })
        .catch((err) => {
          console.log(err);
        });
      this.$api.agreement
        .listByProjectByStaffToNam({
          pageSize: 10000,
          pageNow: 1,
        })
        .then((res) => {
          this.agreementList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.invoiceBillNo = null;
      this.parameter.billStatus = null;
      this.parameter.billType = null;
      this.parameter.invoiceType = null;
      this.parameter.invoiceCode = null;
      this.parameter.invoceNo = null;
      this.parameter.pageNow = 1;

      this.getTableData(); /** 加载 "发票" 数据 **/
    },
    /** 加载 "发票申请" 数据 **/
    getTableData() {
      this.loading = true;
      this.$api.invoice
        .queryPurchaseInvoice(this.parameter)
        .then((res) => {
          this.tableData = res.data?.records;
          this.parameter.total = res.data?.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      
      this.options = item;
      this.options.title = "发票详情";
      this.options.list_s = 4;
      this.options.export = 1
      this.editShow = true;
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page;
      this.getTableData(); /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val;
      this.parameter.pageNow = 1;
      this.getTableData();
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1;
      }
      let obj = { pageSizeLog: this.pageSize };
      this.loading = true;
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then((res) => {
          this.loading = false;
          this.parameter.pageSize = this.pageSize;
          this.userInfo.pageSizeLog = this.pageSize;
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          this.getTableData();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.editShow = false;
      this.getTableData(); /** 加载 "发票申请" 数据 **/
    },
  },
};
</script>

<style scoped>
/* .mainList .mainList_content .mainList_content_sub .el-table {
    height: calc(100% - 93px);
  } */
</style>
